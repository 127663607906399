<template>
    <div class="row">
        <div class="col-12 mb-1">
            <label class="form-label">Tonaj Bilgisi</label>
            <input type="number" class="form-control" v-model="value.Tonnage" placeholder="0" />
        </div>        
    </div>
</template>

<script>
export default {
    props:{
        value:Object
    },
    data(){
        return{

        }
    },
    mounted(){
       
    },
    methods:{
        
    },
    computed:{
        
    }
}
</script>

<style>

</style>