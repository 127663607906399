<template>
    <div class="row">

        <table v-if="value.CounterTonnage != null" class="table table-sm table-bordered align-middle">
            <thead>
                <tr>
                    <td><strong>Düzenlenmiş Tonaj</strong></td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{{ value.CounterTonnage }}</td>

                </tr>
            </tbody>
        </table>
        <table  v-if="value.FixedFirmNameId != null" class="table table-sm table-bordered align-middle">
            <thead>
                <tr>
                    <td><strong>Düzenlenmiş Firma Adı</strong></td>
                   
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{{ value.FixedFirmName }}</td>
                  
                </tr>
            </tbody>
        </table>
        
        <div v-if="value.ExtraRoute.BitisFirmaAdi != null" class="row col-12 justify-content-start">
            <h5 >Dönüş İrsaliye</h5>
        </div>
     
        
        <table class="table table-sm table-bordered align-middle" v-if="value.ExtraRoute.BitisFirmaAdi != null">
            <thead>
                <tr>
                    <td class="w-25"><strong>İrsaliye No</strong></td>
                    <td class="w-25"><strong>Tedarikçi Firma Adı</strong></td>
                    <td class="w-25"><strong>Tonaj</strong></td>
                    <td class="w-25"><strong>Taşıma tipi</strong></td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{{ value.ExtraRoute.IrsaliyeNo }}</td>
                    <td>{{ value.ExtraRoute.BitisFirmaAdi }}</td>
                    <td>{{ value.ExtraRoute.Tonnage }}</td>
                    <td>{{ value.ExtraRoute.VehicleTransportStatus }}</td>
                </tr>
            </tbody>
        </table>
        <table class="table table-sm table-bordered align-middle" v-if="value.ExtraRoute.BitisFirmaAdi != null">
            <thead>
                <tr>
                    <td class="w-20"><strong>Bölge</strong></td>
                    <td class="w-20"><strong>Stok</strong></td>
                    <td class="w-20"><strong>Taşıyıcı Araç Plakası</strong></td>
                    <td class="w-20"><strong>Taşıyıcı Araç Dorse Plaka</strong></td>
                    <td class="w-20"><strong>Tarih</strong></td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{{ value.ExtraRoute.RegionName }}</td>
                    <td>{{ value.ExtraRoute.StockName }}</td>
                    <td>{{ value.ExtraRoute.TasiyiciAracPlaka }}</td>
                    <td>{{ value.ExtraRoute.TasiyiciAracDorsePlaka }}</td>
                    <td>{{ value.ExtraRoute.Gun }}</td>
                </tr>
            </tbody>
        </table>
        
    </div>
</template>

<script>
export default {
    props:{
        value:Object
    },
    data(){
        return{

        }
    },
    mounted(){
       
    },
    methods:{
        
    },
    computed:{
        
    }
}
</script>

<style>

</style>