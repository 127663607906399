<template>
    <div class="row">
        <div class="col-12 mb-1">
            <b-form-group label="Taşıma Tipi" v-slot="{ ariaDescribedby }">
                <b-form-radio v-model="value.VehicleTransportStatus" :aria-describedby="ariaDescribedby" name="some-radios" value="0">Boş/Dolu</b-form-radio>
                <b-form-radio v-model="value.VehicleTransportStatus" :aria-describedby="ariaDescribedby" name="some-radios" value="1">Dolu/Dolu</b-form-radio>
            </b-form-group>
        </div>     
        <div class="col-12 mb-1">
             <label class="form-label">İrsaliye No</label>
            <input class="form-control" v-model="value.IrsaliyeNo" placeholder="İrsaliye No" />
        </div>   
        <div class="col-12 mb-1">
             <label class="form-label">Firma Adı</label>
            <input class="form-control" v-model="value.BitisFirmaAdi" placeholder="Firma Adı" />
        </div> 
        <div class="col-12 mb-1">
            <label class="form-label">Tonaj</label>
            <input type="number" class="form-control" v-model="value.Tonnage" placeholder="0" />
        </div>     
        <div class="col-md-12 mb-1">
            <label class="form-label">Bölge</label>
            <v-select-list v-model="region" placeholder="Bölge" label="RegionName" :options="regions"/>   
        </div>
        <div class="col-md-12 mb-1">
            <label class="form-label">Stok</label>
            <v-select-list v-model="stok" :options="stoks" label="Name" placeholder="Stok" :loading="stokLoading"></v-select-list>
        </div>
        <div class="col-12 mb-1">
             <label class="form-label">Taşıyıcı Araç Plakası</label>
            <input class="form-control" v-model="value.TasiyiciAracPlaka" placeholder="Taşıyıcı Araç Plakası" />
        </div> 
        <div class="col-12 mb-1">
             <label class="form-label">Taşıyıcı Araç Dorse Plaka</label>
            <input class="form-control" v-model="value.TasiyiciAracDorsePlaka" placeholder="Taşıyıcı Araç Dorse Plaka" />
        </div> 
        <div class="col-md-12">
            <label class="form-label">Tarih</label>
            <v-date-picker v-model="value.Gun" placeholder="Tarih"></v-date-picker>
        </div>
    </div>
</template>

<script>
import { BFormGroup,BFormRadio } from 'bootstrap-vue';
import {TransferRegionApi} from '@/api/transfer-region'
import { NetsisApi } from '@/api/netsis'
import { MaterialApi } from '@/api/material'
export default {
    components:{
        BFormRadio,
        BFormGroup
    },
    props:{
        value:Object
    },
    data(){
        return{
            regions:[],
            stoks:[],
            stokLoading:null
        }
    },
    mounted(){
       this.getRegions()
       this.getStoks()
    },
    methods:{
        getRegions(){
            TransferRegionApi.List().then(res=>{
                this.regions = res.data.Data
            })
        },
        // getStoks(searchStokKey){
        //     if(searchStokKey){
        //         this.stokLoading = true
        //         NetsisApi.StokList(searchStokKey).then(res => {
        //             this.stoks = res.data.Data
        //             this.stokLoading = false
        //         }).catch(err=> {
        //             console.log(err)
        //             this.stokLoading = false
        //         })
        //     }
        // },
        getStoks(){
            this.stokLoading = true
            MaterialApi.List().then(res => {
                this.stoks = res.data.Data
                this.stokLoading = false
            }).catch(err=> {
                console.log(err)
                this.stokLoading = false
            })
        },
    },
    computed:{
        region:{
            get:function(){
                return this.regions.find(b=>b.Id==this.value.RegionId)
            },
            set:function(value){
                this.value.RegionName =value?.RegionName ||null
                this.value.RegionId = value?.Id || null
            }
        },
        stok:{
            get:function(){
                return this.stoks.find(s=>s.Code==this.value.StockCode)
            },
            set:function(value){
                this.value.StockCode = value?.Code || null
                this.value.StockName = value?.Name || null                
            }
        }
    }
}
</script>

<style>

</style>