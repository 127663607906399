<template>
    <b-card>
        <b-modal id="tonnage-edit" :title=" EditTonnage.IrsaliyeNo  +' - İrsaliye Tonaj Bilgisi Güncelle'" no-close-on-esc no-close-on-backdrop>
            <counter-tonnage-edit v-model="EditTonnage"></counter-tonnage-edit>
            <template #modal-footer><v-executer :loading="counterTonnageLoading" :action="editTonnage"></v-executer></template>
        </b-modal>
        <b-modal id="bitis-firm-edit" :title=" EditIrsaliyeSeferBitisFirm.IrsaliyeNo  +' - İrsaliye Bitiş Firması Düzenle'" no-close-on-esc no-close-on-backdrop>
            <bitis-firm-edit v-model="EditIrsaliyeSeferBitisFirm"></bitis-firm-edit>
            <template #modal-footer><v-executer :loading="counterTonnageLoading" :action="editBitisFirm"></v-executer></template>
        </b-modal>
        <b-modal id="irsaliye-sefer-extra-route-add" :title=" IrsaliyeSeferExtraRoute.ParentIrsaliyeNo + ' - İrsaliyesine bağlı ek sefer işlemi'" no-close-on-esc no-close-on-backdrop>
            <irsaliye-sefer-extra-route-add v-model="IrsaliyeSeferExtraRoute"></irsaliye-sefer-extra-route-add>
            <template #modal-footer><v-executer :loading="counterTonnageLoading" :action="editSeferExtraRoute"></v-executer></template>
        </b-modal>
        <b-modal id="change-request-detail" :title="ChangeRequestDetail.IrsaliyeNo + ' İrsaliyesine Bağlı Değişiklik Talepleri'" hide-footer size="lg" no-close-on-esc no-close-on-backdrop>
            <change-request-detail-view v-model="ChangeRequestDetail"></change-request-detail-view>
        </b-modal>
        <div class="row">
            <div class="col-md-12 mb-1 ">
                <v-stats-box text="Durum" variant="success" icon="info" :value="IrsaliyeGroup.StatusStr" ></v-stats-box>
            </div>
            <div class="col-md-6 mb-1 ">
                <v-stats-box text="Referans No" variant="warning" icon="info" :value="IrsaliyeGroup.ReferenceNo" ></v-stats-box>
            </div>
            <div class="col-md-6 mb-1">
                <v-stats-box text="Taşıyıcı Cari Adı" variant="success" icon="handshake" :value="IrsaliyeGroup.CarrierCariName" ></v-stats-box>
            </div>
            
            <div class="col-md-6 mb-1 ">
                <v-stats-box text="Tarih Aralığı" variant="danger" icon="event" :value="IrsaliyeGroup.StartDate +' - ' + IrsaliyeGroup.EndDate" ></v-stats-box>
            </div>
            <div class="col-md-6 mb-1 ">
                <v-stats-box text="Oluşturulma Tarihi" variant="primary" icon="event" :value="IrsaliyeGroup.CreateTime" ></v-stats-box>
            </div>
            
        </div>
        <hr>
        <div v-if="IrsaliyeGroupDetailStats.length>0 && (IrsaliyeGroup.Status == 9 || IrsaliyeGroup.Status ==10 || IrsaliyeGroup.Status ==11)" class="row mb-2">
                <h2>Özet Fiyat Bilgisi</h2>
                <table class="table table-sm align-middle">
                    <thead>
                        <tr>
                            <th>Varış Noktası</th>
                            <th>Taşıma Durumu</th>
                            <th>Malzeme</th>
                            <th>Araç Tipi</th>
                            <th>Toplam Tonaj</th>
                            <th>Birim Fiyat</th>
                            <th>Toplam Tutar</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(data, index) in IrsaliyeGroupDetailStats">
                            <tr :key="index">
                                    <td class="text-nowrap">{{ data.CustomerName }}</td>
                                    <td class="text-nowrap">{{ data.VehicleTransportStatus }}</td>
                                    <td class="text-nowrap">{{ data.MaterialName }}</td>
                                    <td class="text-nowrap">{{ data.VehicleType }}</td>
                                    <td class="text-nowrap">{{ data.TotalTonnage }}</td>
                                    <td class="text-nowrap">{{ data.UnitPrice }}</td>
                                    <td class="text-nowrap">{{ data.TotalPrice }}</td>
                                </tr>
                        </template>
                    </tbody>
                </table>
        </div>
        <div class="row">
            <h2>Seferler</h2>
            <div class="row mt-2 table-responsive">
                <table class="table table-sm table-bordered align-middle" >
                    <thead>
                        <tr>
                            <th v-if="IrsaliyeGroup.Status != 6" class="text-nowrap">İşlemler</th>
                            <th  v-if="IrsaliyeGroup.Status == 8 && IrsaliyeGroup.Status != 9" title="Değişiklik Talep Durumu" class="text-nowrap">Değ. Talep Durumu</th>
                            <th  class="text-nowrap">İrsaliye No</th>
                            <th  class="text-nowrap">Tarih</th>
                            <th  class="text-nowrap">Başlangıç Bölge</th>
                            <th  class="text-nowrap">Taşınan Firma Adı</th>
                            <th  v-if="IrsaliyeGroup.Status ==  1" class="text-nowrap">Düzenlenen Taşınan Firma Adı</th>
                            <th  class="text-nowrap">Bitiş Bölge</th>
                            <th  class="text-nowrap">Araç Tipi</th>
                            <th  class="text-nowrap">Plaka</th>
                            <th  class="text-nowrap">Ürün Tipi</th>
                            <th  class="text-nowrap">Ton</th>
                            <th  v-if="IrsaliyeGroup.IsCounterTonnage && IrsaliyeGroup.Status ==  1" class="text-nowrap">Tonaj Bilgisi</th>
                            <th  v-if="IrsaliyeGroup.Status == 9 || IrsaliyeGroup.Status == 6" class="text-nowrap">Fiyat</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(data, ind) in $safe(IrsaliyeGroup,'IrsaliyeSefers')">
                            <tr :key="ind">
                                <td v-if="IrsaliyeGroup.Status !=  6" class="text-nowrap">
                                    
                                    <b-dropdown v-if="(IrsaliyeGroup.Status == 1 ||( IrsaliyeGroup.Status ==  8 && data.IsChangeRequestHas))" dropright size="sm" variant="light" no-caret>
                                        <template #button-content>
                                            <feather-icon class="mr-50" icon="MoreHorizontalIcon"></feather-icon>
                                            İşlemler
                                        </template>
                                        <b-dropdown-item  variant="primary" v-if="IrsaliyeGroup.IsCounterTonnage && IrsaliyeGroup.Status ==  1" v-on:click="openTonnageEditModal(data)">
                                                <feather-icon class="mr-50" icon="ChevronsRightIcon" size="16"></feather-icon>
                                                Tonaj Bilgisi Düzenle
                                        </b-dropdown-item>
                                        <b-dropdown-item v-if="IrsaliyeGroup.Status ==  1"  variant="success" v-on:click="openEditBitisFirmNameModal(data)">
                                                <feather-icon class="mr-50" icon="LayoutIcon" size="16"></feather-icon>
                                                Taşınan Firma Bilgisini Düzenle
                                        </b-dropdown-item>
                                        <b-dropdown-item v-if="IrsaliyeGroup.Status ==  8" variant="primary"  v-on:click="getChangeRequestDetail(data)">
                                                <feather-icon class="mr-50" icon="InfoIcon" size="16" ></feather-icon>
                                                Değişiklik Talebini Görüntüle
                                        </b-dropdown-item> 
                                    </b-dropdown>
                                    
                                </td>
                                <td v-if="IrsaliyeGroup.Status !=  6 && IrsaliyeGroup.Status ==  8">
                                    <div class="text-center">
                                            <span v-if="$safe(data,'IsChangeRequestHas') == true" class="material-icons-outlined text-success">check_circle</span>
                                            <span v-else class="material-icons-outlined text-danger">cancel</span>
                                    </div>
                                </td>
                                <td class="text-nowrap">{{ $safe(data,'IrsaliyeNo') }}</td>
                                <td class="text-nowrap">{{ data.Gun }}</td>
                                <td class="text-nowrap">{{ $safe(data,'BaslangicRegion') }}</td>
                                <td class="text-nowrap">{{ $safe(data,'BitisFirmaAdi') }}</td>
                                <td  v-if="IrsaliyeGroup.Status ==  1" class="text-nowrap">
                                    <b-badge  variant="success">{{ $safe(data,'FixedFirmaAdi') }}</b-badge>
                                    
                                </td>
                                <td class="text-nowrap">{{ $safe(data,'BitisRegion') }}</td>
                                <td class="text-nowrap">{{ $safe(data,'VehicleTypeName') }}</td>
                                <td class="text-nowrap">{{ $safe(data,'TasiyiciAracPlaka') }}</td>
                                <td class="text-nowrap">{{ $safe(data,'TasinanUrunAdi') }}</td>
                                <td class="text-nowrap">{{ $safe(data,'TasinanUrunMiktariStr') }}</td>
                                <td v-if="IrsaliyeGroup.IsCounterTonnage && IrsaliyeGroup.Status ==  1" class="text-nowrap">
                                    <b-badge  variant="primary">{{ $safe(data,'CounterTonnage') }}</b-badge>
                                </td>
                                <td v-if="IrsaliyeGroup.Status == 9 || IrsaliyeGroup.Status == 6" class="text-nowrap text-right">
                                    <b-badge variant="danger">{{ $safe(data,'PriceStr') +' ₺'}} </b-badge>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row justify-content-end">
            <div v-if="IrsaliyeGroup.Status ==  1" class="col-auto mb-1 mb-md-0">
                <v-executer :loading="sendIrsaliyeLoading" :action="sendIrsaliyeGroupToControl">Onayla</v-executer>
            </div>
            
        </div>
    </b-card>
</template>

<script>
import { BBadge, BCard,BFormCheckbox,BDropdown,BDropdownItem } from 'bootstrap-vue'
import { IrsaliyeOperationsApi } from '@/api/irsaliye'
import VStatsBox from '@/components/VStatsBox.vue'
import CounterTonnageEdit from './components/CounterTonnageEdit.vue'
import BitisFirmEdit from './components/BitisFirmEdit.vue'
import IrsaliyeSeferExtraRouteAdd from './components/IrsaliyeSeferExtraRouteAdd.vue'
import ChangeRequestDetailView from './components/ChangeRequestDetailView.vue'
export default {
    components:{
        ChangeRequestDetailView,
        IrsaliyeSeferExtraRouteAdd,
        BDropdown,
        BDropdownItem,
        BitisFirmEdit,
        CounterTonnageEdit,
        BCard,
        BBadge,
        VStatsBox,
        BFormCheckbox
    },
    data(){
        return{
            IrsaliyeGroupDetailStats:[],
            EditTonnage:{
                Id:null,
                Tonnage:null,
                IrsaliyeNo:null
            },
            EditIrsaliyeSeferBitisFirm:{
                Id:null,
                FirmName:null,
                IrsaliyeNo:null
            },
            counterTonnageLoading:false,
            IrsaliyeGroup:{
                Id:null,
                CarrierCariName:null,
                CarrierCariCode:null,
                ReferenceNo:null,
                CreateTime:null,
                StartDate:null,
                EndDate:null,
                IrsaliyeSefers:[],
                Status:null,
                StatusStr:null,
                IsCounterTonnage:null
            },
            IrsaliyeSeferExtraRoute:{
                VehicleTransportStatus:0,
                ParentIrsaliyeGroupSeferId:null,
                ParentIrsaliyeNo:null,
                BitisFirmaAdi:null,
                IrsaliyeNo:null,
                Tonnage:null,
                RegionId:null,
                TasinanUrunAdi:null,
                TasiyiciAracPlaka:null,
                TasiyiciAracDorsePlaka:null,
                Gun:null,
                StockCode:null

            },
            ChangeRequestDetail:{
                IrsaliyeNo:null,
                CounterTonnageRequestId:null,
                CounterTonnage:null,
                FixedFirmNameId:null,
                FixedFirmName:null,
                ExtraRouteId:null,
                ExtraRoute:{
                    BitisFirmaAdi:null,
                    IrsaliyeNo:null,
                    Tonnage:null,
                    VehicleTransportStatus:null,
                    RegionId:null,
                    TasinanUrunAdi:null,
                    TasiyiciAracPlaka:null,
                    TasiyiciAracDorsePlaka:null,
                    Gun:null,
                    StockCode:null,
                    StockName:null
                },
                CounterTonnageStatus:null,
                CounterTonnageStatusValue:null,
                FixedFirmNameStatus:null,
                FixedFirmNameStatusValue:null,
                ExtraRouteStatus:null,
                ExtraRouteStatusValue:null
            },
            sendIrsaliyeLoading:false
        }
    },
    mounted(){
        this.getDetail()
    },
    methods:{
        clearSeferExtraRoute(){
            this.IrsaliyeSeferExtraRoute.ParentIrsaliyeNo = null
            this.IrsaliyeSeferExtraRoute.VehicleTransportStatus = 0
            this.IrsaliyeSeferExtraRoute.ParentIrsaliyeGroupSeferId = null
            this.IrsaliyeSeferExtraRoute.BitisFirmaAdi = null
            this.IrsaliyeSeferExtraRoute.IrsaliyeNo = null
            this.IrsaliyeSeferExtraRoute.Tonnage = null
            this.IrsaliyeSeferExtraRoute.RegionId = null
            this.IrsaliyeSeferExtraRoute.TasinanUrunAdi = null
            this.IrsaliyeSeferExtraRoute.TasiyiciAracPlaka = null
            this.IrsaliyeSeferExtraRoute.TasiyiciAracDorsePlaka = null
            this.IrsaliyeSeferExtraRoute.Gun = null
            this.IrsaliyeSeferExtraRoute.StockCode = null
            this.IrsaliyeSeferExtraRoute.StockName = null
        },
        getChangeRequestDetail(data){
            this.ChangeRequestDetail.IrsaliyeNo = data.IrsaliyeNo
            this.ChangeRequestDetail.CounterTonnageRequestId = data.ChangeRequests.CounterTonnageRequestId
            this.ChangeRequestDetail.CounterTonnage = data.ChangeRequests.CounterTonnage
            this.ChangeRequestDetail.FixedFirmNameId = data.ChangeRequests.FixedFirmNameId
            this.ChangeRequestDetail.FixedFirmName = data.ChangeRequests.FixedFirmName
            this.ChangeRequestDetail.ExtraRouteId = data.ChangeRequests.ExtraRouteId
            this.ChangeRequestDetail.CounterTonnageStatus = data.ChangeRequests.CounterTonnageStatus
            this.ChangeRequestDetail.CounterTonnageStatusValue = data.ChangeRequests.CounterTonnageStatusValue
            this.ChangeRequestDetail.FixedFirmNameStatus = data.ChangeRequests.FixedFirmNameStatus
            this.ChangeRequestDetail.FixedFirmNameStatusValue = data.ChangeRequests.FixedFirmNameStatusValue
            this.ChangeRequestDetail.ExtraRouteStatus = data.ChangeRequests.ExtraRouteStatus
            this.ChangeRequestDetail.ExtraRouteStatusValue = data.ChangeRequests.ExtraRouteStatusValue
            this.ChangeRequestDetail.ExtraRoute.BitisFirmaAdi = data.ChangeRequests.ExtraRoute?.BitisFirmaAdi
            this.ChangeRequestDetail.ExtraRoute.Tonnage = data.ChangeRequests.ExtraRoute?.Tonnage
            this.ChangeRequestDetail.ExtraRoute.IrsaliyeNo = data.ChangeRequests.ExtraRoute?.IrsaliyeNo
            this.ChangeRequestDetail.ExtraRoute.VehicleTransportStatus = data.ChangeRequests.ExtraRoute?.VehicleTransportStatus
            this.ChangeRequestDetail.ExtraRoute.Gun = data.ChangeRequests.ExtraRoute?.Gun
            this.ChangeRequestDetail.ExtraRoute.RegionName = data.ChangeRequests.ExtraRoute?.RegionName
            this.ChangeRequestDetail.ExtraRoute.StockCode = data.ChangeRequests.ExtraRoute?.StockCode
            this.ChangeRequestDetail.ExtraRoute.StockName = data.ChangeRequests.ExtraRoute?.StockName
            this.ChangeRequestDetail.ExtraRoute.TasiyiciAracDorsePlaka = data.ChangeRequests.ExtraRoute?.TasiyiciAracDorsePlaka
            this.ChangeRequestDetail.ExtraRoute.TasiyiciAracPlaka = data.ChangeRequests.ExtraRoute?.TasiyiciAracPlaka
            this.$showModal('change-request-detail')
        },
        clearEditTonnage(){
            this.EditTonnage.Id=null
            this.EditTonnage.Tonnage=null
            this.EditTonnage.IrsaliyeNo = null
        },
        clearEditBitisFirmName(){
            this.EditIrsaliyeSeferBitisFirm.Id = null
            this.EditIrsaliyeSeferBitisFirm.FirmName = null
        },
        deleteSeferExtraRouteModal(id){
            let irsaliyeSefer = this.IrsaliyeGroup.IrsaliyeSefers.filter(c=> c.Id == id)[0];
            if(irsaliyeSefer.IrsaliyeSeferExtraRoute.ParentIrsaliyeGroupSeferId != null){
                this.$confirm('İlgili İrsaliyeye Ait Ek Rota Silinecektir',`Bu işlem ${irsaliyeSefer.IrsaliyeSeferExtraRoute.ParentIrsaliyeNo} irsaliyesine ait olan ek rotayı silecektir. Onaylıyor musunuz?`).then(result=>{
                if(result){
                    irsaliyeSefer.IrsaliyeSeferExtraRoute.ParentIrsaliyeNo = null
                    irsaliyeSefer.IrsaliyeSeferExtraRoute.ParentIrsaliyeGroupSeferId = null
                    irsaliyeSefer.IrsaliyeSeferExtraRoute.VehicleTransportStatus = 0
                    irsaliyeSefer.IrsaliyeSeferExtraRoute.BitisFirmaAdi = null
                    irsaliyeSefer.IrsaliyeSeferExtraRoute.IrsaliyeNo = null
                    irsaliyeSefer.IrsaliyeSeferExtraRoute.Tonnage = null
                    irsaliyeSefer.IrsaliyeSeferExtraRoute.RegionId = null
                    irsaliyeSefer.IrsaliyeSeferExtraRoute.TasinanUrunAdi = null
                    irsaliyeSefer.IrsaliyeSeferExtraRoute.TasiyiciAracPlaka = null
                    irsaliyeSefer.IrsaliyeSeferExtraRoute.TasiyiciAracDorsePlaka = null
                    irsaliyeSefer.IrsaliyeSeferExtraRoute.Gun = null
                    irsaliyeSefer.IrsaliyeSeferExtraRoute.StockCode = null
                    irsaliyeSefer.IrsaliyeSeferExtraRoute.StockName = null
                }
            })
            }
        },
        editSeferExtraRoute(){
            this.IrsaliyeGroup.IrsaliyeSefers.map(c=> {
                if(c.Id==this.IrsaliyeSeferExtraRoute.ParentIrsaliyeGroupSeferId){
                    c.IrsaliyeSeferExtraRoute.ParentIrsaliyeNo = this.IrsaliyeSeferExtraRoute.ParentIrsaliyeNo
                    c.IrsaliyeSeferExtraRoute.ParentIrsaliyeGroupSeferId = this.IrsaliyeSeferExtraRoute.ParentIrsaliyeGroupSeferId 
                    c.IrsaliyeSeferExtraRoute.VehicleTransportStatus = this.IrsaliyeSeferExtraRoute.VehicleTransportStatus 
                    c.IrsaliyeSeferExtraRoute.BitisFirmaAdi = this.IrsaliyeSeferExtraRoute.BitisFirmaAdi 
                    c.IrsaliyeSeferExtraRoute.IrsaliyeNo = this.IrsaliyeSeferExtraRoute.IrsaliyeNo 
                    c.IrsaliyeSeferExtraRoute.Tonnage = this.IrsaliyeSeferExtraRoute.Tonnage 
                    c.IrsaliyeSeferExtraRoute.RegionId = this.IrsaliyeSeferExtraRoute.RegionId 
                    c.IrsaliyeSeferExtraRoute.TasinanUrunAdi = this.IrsaliyeSeferExtraRoute.TasinanUrunAdi
                    c.IrsaliyeSeferExtraRoute.TasiyiciAracPlaka = this.IrsaliyeSeferExtraRoute.TasiyiciAracPlaka
                    c.IrsaliyeSeferExtraRoute.TasiyiciAracDorsePlaka = this.IrsaliyeSeferExtraRoute.TasiyiciAracDorsePlaka
                    c.IrsaliyeSeferExtraRoute.Gun = this.IrsaliyeSeferExtraRoute.Gun
                    c.IrsaliyeSeferExtraRoute.StockCode =  this.IrsaliyeSeferExtraRoute.StockCode
                    c.IrsaliyeSeferExtraRoute.StockName =  this.IrsaliyeSeferExtraRoute.StockName
                }
                return c
            })
            this.$hideModal('irsaliye-sefer-extra-route-add')
        },
        
        openEditSeferExtraRouteModal(data){
            this.clearSeferExtraRoute()
            this.IrsaliyeSeferExtraRoute.ParentIrsaliyeNo = data.IrsaliyeNo
            this.IrsaliyeSeferExtraRoute.ParentIrsaliyeGroupSeferId = data.Id
            this.IrsaliyeSeferExtraRoute.VehicleTransportStatus = data.IrsaliyeSeferExtraRoute.VehicleTransportStatus
            this.IrsaliyeSeferExtraRoute.BitisFirmaAdi = data.IrsaliyeSeferExtraRoute.BitisFirmaAdi
            this.IrsaliyeSeferExtraRoute.IrsaliyeNo = data.IrsaliyeSeferExtraRoute.IrsaliyeNo
            this.IrsaliyeSeferExtraRoute.Tonnage = data.IrsaliyeSeferExtraRoute.Tonnage
            this.IrsaliyeSeferExtraRoute.RegionId = data.IrsaliyeSeferExtraRoute.RegionId
            this.IrsaliyeSeferExtraRoute.TasinanUrunAdi = data.IrsaliyeSeferExtraRoute.TasinanUrunAdi
            this.IrsaliyeSeferExtraRoute.TasiyiciAracPlaka = data.IrsaliyeSeferExtraRoute.TasiyiciAracPlaka
            this.IrsaliyeSeferExtraRoute.TasiyiciAracDorsePlaka = data.IrsaliyeSeferExtraRoute.TasiyiciAracDorsePlaka
            this.IrsaliyeSeferExtraRoute.Gun = data.IrsaliyeSeferExtraRoute.Gun
            this.IrsaliyeSeferExtraRoute.StockCode =  data.IrsaliyeSeferExtraRoute.StockCode
            this.IrsaliyeSeferExtraRoute.StockName =  data.IrsaliyeSeferExtraRoute.StockName

            this.$showModal('irsaliye-sefer-extra-route-add')
        },
        editTonnage(){
            this.IrsaliyeGroup.IrsaliyeSefers.map(c=> {
                if(c.Id==this.EditTonnage.Id){
                    c.CounterTonnage = this.EditTonnage.Tonnage
                }
                return c
            })
            this.$hideModal('tonnage-edit')
            
        },
        editBitisFirm(){
            this.IrsaliyeGroup.IrsaliyeSefers.map(c=> {
                if(c.Id==this.EditIrsaliyeSeferBitisFirm.Id){
                    c.FixedFirmaAdi = this.EditIrsaliyeSeferBitisFirm.FirmName
                }
                return c
            })
            this.$hideModal('bitis-firm-edit')
            
        },
        
        openEditBitisFirmNameModal(data){
            this.clearEditBitisFirmName()
            this.EditIrsaliyeSeferBitisFirm.Id = data.Id
            this.EditIrsaliyeSeferBitisFirm.IrsaliyeNo = data.IrsaliyeNo
            this.$showModal('bitis-firm-edit')
        },
        openTonnageEditModal(data){
            this.clearEditTonnage()
            this.EditTonnage.Id = data.Id
            this.EditTonnage.IrsaliyeNo=data.IrsaliyeNo
            this.$showModal('tonnage-edit')
        },
        getDetail(){
            const id = this.$route.params.id
            IrsaliyeOperationsApi.Detail(id).then(res => {
                if(!res.data.Success)
                    this.$result(res)
                else{
                    this.IrsaliyeGroup.Id=res.data.Data.Id
                    this.IrsaliyeGroup.IrsaliyeNo = res.data.Data.IrsaliyeNo
                    this.IrsaliyeGroup.CarrierCariName = res.data.Data.CarrierCariName
                    this.IrsaliyeGroup.CarrierCariCode = res.data.Data.CarrierCariCode
                    this.IrsaliyeGroup.ReferenceNo = res.data.Data.ReferenceNo
                    this.IrsaliyeGroup.CreateTime = res.data.Data.CreateTime
                    this.IrsaliyeGroup.StartDate = res.data.Data.StartDate
                    this.IrsaliyeGroup.EndDate = res.data.Data.EndDate
                    this.IrsaliyeGroup.Status = res.data.Data.Status
                    this.IrsaliyeGroup.StatusStr = res.data.Data.StatusStr
                    this.IrsaliyeGroup.IsCounterTonnage = res.data.Data.IsCounterTonnage
                    this.IrsaliyeGroup.IrsaliyeSefers = res.data.Data.IrsaliyeSefers
                    this.IrsaliyeGroup.IrsaliyeSefers = this.IrsaliyeGroup.IrsaliyeSefers.map(f => {
                        return {
                            ...f,
                            CounterTonnage:null,
                            FixedFirmaAdi:null,
                            IrsaliyeSeferExtraRoute:{
                                ParentIrsaliyeNo:null,
                                VehicleTransportStatus:0,
                                ParentIrsaliyeGroupSeferId:null,
                                BitisFirmaAdi:null,
                                IrsaliyeNo:null,
                                Tonnage:null,
                                RegionId:null,
                                TasinanUrunAdi:null,
                                TasiyiciAracPlaka:null,
                                TasiyiciAracDorsePlaka:null,
                                Gun:null,
                                StockCode:null,
                                StockName:null

                            }
                        }
                    })
                    IrsaliyeOperationsApi.DetailStats(id).then(res => {
                        if(!res.data.Success)
                            this.$result(res)
                        else{
                            this.IrsaliyeGroupDetailStats = res.data.Data
                        }
                    }).catch(err => {
                        console.error(err);
                    })

                }

            }).catch(err => {
                console.error(err);
            })
        },
        sendToCustomer(){
            this.$confirm('İlgili İrsaliye Grubu Tedarikçiye Gönderilecektir. Onaylıyor musunuz?',`Bu işlem, ${this.IrsaliyeGroup.ReferenceNo} adlı irsaliye grubunu tedarikçiye onay için gönderecektir.`).then(result=>{
                if(result){
                    IrsaliyeOperationsApi.SendToCustomer(this.IrsaliyeGroup.Id).then(res => {
                        this.$result(res)
                        if(res.data.Success){
                            this.getDetail()
                        }
                    })
                }
            })
        },
        sendIrsaliyeGroupToControl(){
            this.sendIrsaliyeLoading=true
            this.$confirm('İlgili İrsaliye Grubu Muhasebe Departmanına Onay İçin Gönderilecektir. Onaylıyor Musunuz?',
                            `Bu işlem ${this.IrsaliyeGroup.ReferenceNo} adlı irsaliye grubunu muhasebe departmanına onay için gönderecektir.`).then(result=>{
                if(result){
                    IrsaliyeOperationsApi.SendIrsaliyeGroupToControl(this.IrsaliyeGroup).then(res => {
                        this.sendIrsaliyeLoading=false
                        this.$result(res)
                        if(res.data.Success){
                            this.getDetail()
                        }
                    }).then(err=>{
                        this.sendIrsaliyeLoading=false
                    })
                }
            })
        }
    }
}
</script>

<style>

</style>